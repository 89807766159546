
.AppOverallDesign {
  background: linear-gradient(90deg, #0b1d3b, #06001a);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
}

.UserHeader {
  background-color: rgba(73, 73, 73, 0.418);
  backdrop-filter: blur(10px);
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.UserHeaderChildren {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  max-width: 800px;
  padding: 10px;
}

.UserHeaderChildren2 {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  gap: 10px;
  max-width: 800px;
  justify-content: center;
  flex-wrap: wrap;
}

.button-42 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #aaa9a9, #7c7472);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  height: 37px;
  outline: 0;
  font-weight: bold;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
  align-self: center;
}

.button-43 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #aaa9a9, #7c7472);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  outline: 0;
  font-weight: bold;
  overflow: hidden;
  padding: 0 5px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
  align-self: center;
}

.button-44 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #aaa9a9, #302e2e);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  outline: 0;
  font-weight: bold;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
  align-self: center;
  min-width: 90px;
  font-size: large;
}


.input-style1 {
  background-color: #ffffff;
  border-color: #ccc;
  border-radius: 5px;
  width: 250px;
  height: 30px;
  font-size: 16px;
}

.input-style2 {
  background-color: #ffffff;
  border-color: #ccc;
  border-radius: 5px;
  width: 200px;
  height: 37px;
  font-size: 16px;
}

.Gap10 {
  gap: 10px;
  display: flex;
  align-items: center;
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 95%;
  max-width: 1400px;
  flex: 1;
  margin: 0 auto;
  overflow-x: hidden;
}


.EingabeV2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  gap: 0;
  max-width: 1000px;
  min-height: 100px;
}

.EingabeV2Children {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.GraphParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  max-width: 1000px;
  min-height: 600px;
  padding: 20px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
}

.Graph {
  flex: 1;
  width: 100%;
  height: 100%;
}

.ListUndInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  min-height: 200px;
  align-items: stretch;

}

.Liste {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: "white";
  min-width: 330px;
  max-width: 100%;
  max-height: 340px;
  gap: 5px;
  flex: 1;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
}

.NurListe {
  max-height: 70%;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 8px;
}

.Info {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  min-width: 330px;
  max-width: 100%;
  max-height: 340px;
  gap: 5px;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
}

.ErrorPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.ErrorBox {
  max-width: 450px;
  max-height: 250px;
  padding: 20px;
  border: 5px solid #7a7a7a;
  background-color: #ffffff;
  border-radius: 8px;
  margin-inline: auto;
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.InfoUndDisclaimerUndExpla {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  align-items: stretch;
  color: white;
  align-items: stretch;
  align-content: stretch;
  margin-inline: 5px;
}

.StartInfo {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
  color: white;
  flex: 1;
}

.Punkte {
  font-size: larger;
}

.Disclaimer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
  color: white;
  flex: 1;
  text-align: center;
}

.Titel {
  text-align: center;
  font-size: larger;
}

.ExplicitExpla {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  background-color: rgba(73, 73, 73, 0.418);
  border-radius: 8px;
  color: white;
  flex: 1;
  text-align: center;
  padding-top: 10px;
  gap: 10px;
  margin-bottom: 20px;

}